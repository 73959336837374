@font-face {
  font-weight: 300;
  font-family: Avenir;
  src: url('./fonts/avenir-lighter.woff') format('woff');
}

@font-face {
  font-weight: 400;
  font-family: Avenir;
  src: url('./fonts/avenir-book.woff') format('woff');
}

@font-face {
  font-weight: 500;
  font-family: Avenir;
  src: url('./fonts/avenir-medium.woff') format('woff');
}

@font-face {
  font-weight: 900;
  font-family: Avenir;
  src: url('./fonts/avenir-black.woff') format('woff');
}

@font-face {
  font-family: icons;
  src: url('./fonts/icons.eot?#iefix') format('embedded-opentype'),
    url('./fonts/icons.woff2') format('woff2'),
    url('./fonts/icons.woff') format('woff'),
    url('./fonts/icons.ttf') format('truetype'),
    url('./fonts/icons.svg#icons') format('svg');
}

[class*='sncf-icons-'] {
  display: inline-block;
  line-height: 1;

  &::before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    vertical-align: top;
  }
}

.sncf-icons-add:before {
  content: '\F101';
}

.sncf-icons-admin:before {
  content: '\F102';
}

.sncf-icons-alert-notification:before {
  content: '\F103';
}

.sncf-icons-ambulant-sales:before {
  content: '\F104';
}

.sncf-icons-arrow-double:before {
  content: '\F105';
}

.sncf-icons-arrow-down:before {
  content: '\F106';
}

.sncf-icons-arrow-next:before {
  content: '\F107';
}

.sncf-icons-arrow-prev:before {
  content: '\F108';
}

.sncf-icons-arrow-real:before {
  content: '\F109';
}

.sncf-icons-arrow-up:before {
  content: '\F10A';
}

.sncf-icons-arrow:before {
  content: '\F10B';
}

.sncf-icons-booking:before {
  content: '\F10C';
}

.sncf-icons-bookmark-off:before {
  content: '\F10D';
}

.sncf-icons-bookmark:before {
  content: '\F10E';
}

.sncf-icons-bypass:before {
  content: '\F10F';
}

.sncf-icons-calendar-time:before {
  content: '\F110';
}

.sncf-icons-calendar:before {
  content: '\F111';
}

.sncf-icons-checked:before {
  content: '\F112';
}

.sncf-icons-circle-account-connected:before {
  content: '\F113';
}

.sncf-icons-circle-arrow:before {
  content: '\F114';
}

.sncf-icons-circle-back-top:before {
  content: '\F115';
}

.sncf-icons-circle-cancelled:before {
  content: '\F116';
}

.sncf-icons-circle-delay:before {
  content: '\F117';
}

.sncf-icons-circle-delete:before {
  content: '\F118';
}

.sncf-icons-circle-disruption:before {
  content: '\F119';
}

.sncf-icons-circle-facebook:before {
  content: '\F11A';
}

.sncf-icons-circle-flux-rss:before {
  content: '\F11B';
}

.sncf-icons-circle-google-plus:before {
  content: '\F11C';
}

.sncf-icons-circle-information:before {
  content: '\F11D';
}

.sncf-icons-circle-instagram:before {
  content: '\F11E';
}

.sncf-icons-circle-linkedin:before {
  content: '\F11F';
}

.sncf-icons-circle-mail:before {
  content: '\F120';
}

.sncf-icons-circle-pinterest:before {
  content: '\F121';
}

.sncf-icons-circle-print:before {
  content: '\F122';
}

.sncf-icons-circle-reverse:before {
  content: '\F123';
}

.sncf-icons-circle-tooltip:before {
  content: '\F124';
}

.sncf-icons-circle-twitter:before {
  content: '\F125';
}

.sncf-icons-circle-works:before {
  content: '\F126';
}

.sncf-icons-clock:before {
  content: '\F127';
}

.sncf-icons-close-circle:before {
  content: '\F128';
}

.sncf-icons-close:before {
  content: '\F129';
}

.sncf-icons-disabled-service:before {
  content: '\F12A';
}

.sncf-icons-distribution:before {
  content: '\F12B';
}

.sncf-icons-document:before {
  content: '\F12C';
}

.sncf-icons-document2:before {
  content: '\F12D';
}

.sncf-icons-download:before {
  content: '\F12E';
}

.sncf-icons-eco-consumption:before {
  content: '\F12F';
}

.sncf-icons-external-link:before {
  content: '\F130';
}

.sncf-icons-facebook:before {
  content: '\F131';
}

.sncf-icons-favorite-on:before {
  content: '\F132';
}

.sncf-icons-file:before {
  content: '\F133';
}

.sncf-icons-filters:before {
  content: '\F134';
}

.sncf-icons-free-bike:before {
  content: '\F135';
}

.sncf-icons-instagram:before {
  content: '\F136';
}

.sncf-icons-itinerary-bike:before {
  content: '\F137';
}

.sncf-icons-itinerary-bullet:before {
  content: '\F138';
}

.sncf-icons-itinerary-bus-2:before {
  content: '\F139';
}

.sncf-icons-itinerary-bus:before {
  content: '\F13A';
}

.sncf-icons-itinerary-car:before {
  content: '\F13B';
}

.sncf-icons-itinerary-line-idf-a:before {
  content: '\F13C';
}

.sncf-icons-itinerary-line-idf-b:before {
  content: '\F13D';
}

.sncf-icons-itinerary-line-idf-c:before {
  content: '\F13E';
}

.sncf-icons-itinerary-line-idf-d:before {
  content: '\F13F';
}

.sncf-icons-itinerary-line-idf-e:before {
  content: '\F140';
}

.sncf-icons-itinerary-line-idf-h:before {
  content: '\F141';
}

.sncf-icons-itinerary-line-idf-j:before {
  content: '\F142';
}

.sncf-icons-itinerary-line-idf-k:before {
  content: '\F143';
}

.sncf-icons-itinerary-line-idf-l:before {
  content: '\F144';
}

.sncf-icons-itinerary-line-idf-n:before {
  content: '\F145';
}

.sncf-icons-itinerary-line-idf-p:before {
  content: '\F146';
}

.sncf-icons-itinerary-line-idf-r:before {
  content: '\F147';
}

.sncf-icons-itinerary-line-idf-u:before {
  content: '\F148';
}

.sncf-icons-itinerary-line-metro-1:before {
  content: '\F149';
}

.sncf-icons-itinerary-line-metro-10:before {
  content: '\F14A';
}

.sncf-icons-itinerary-line-metro-11:before {
  content: '\F14B';
}

.sncf-icons-itinerary-line-metro-12:before {
  content: '\F14C';
}

.sncf-icons-itinerary-line-metro-13:before {
  content: '\F14D';
}

.sncf-icons-itinerary-line-metro-14:before {
  content: '\F14E';
}

.sncf-icons-itinerary-line-metro-2:before {
  content: '\F14F';
}

.sncf-icons-itinerary-line-metro-3:before {
  content: '\F150';
}

.sncf-icons-itinerary-line-metro-3bis:before {
  content: '\F151';
}

.sncf-icons-itinerary-line-metro-4:before {
  content: '\F152';
}

.sncf-icons-itinerary-line-metro-5:before {
  content: '\F153';
}

.sncf-icons-itinerary-line-metro-6:before {
  content: '\F154';
}

.sncf-icons-itinerary-line-metro-7:before {
  content: '\F155';
}

.sncf-icons-itinerary-line-metro-7bis:before {
  content: '\F156';
}

.sncf-icons-itinerary-line-metro-8:before {
  content: '\F157';
}

.sncf-icons-itinerary-line-metro-9:before {
  content: '\F158';
}

.sncf-icons-itinerary-line-tram-1:before {
  content: '\F159';
}

.sncf-icons-itinerary-line-tram-10:before {
  content: '\F15A';
}

.sncf-icons-itinerary-line-tram-11:before {
  content: '\F15B';
}

.sncf-icons-itinerary-line-tram-12:before {
  content: '\F15C';
}

.sncf-icons-itinerary-line-tram-13:before {
  content: '\F15D';
}

.sncf-icons-itinerary-line-tram-2:before {
  content: '\F15E';
}

.sncf-icons-itinerary-line-tram-3:before {
  content: '\F15F';
}

.sncf-icons-itinerary-line-tram-3a:before {
  content: '\F160';
}

.sncf-icons-itinerary-line-tram-3b:before {
  content: '\F161';
}

.sncf-icons-itinerary-line-tram-3est:before {
  content: '\F162';
}

.sncf-icons-itinerary-line-tram-4:before {
  content: '\F163';
}

.sncf-icons-itinerary-line-tram-5:before {
  content: '\F164';
}

.sncf-icons-itinerary-line-tram-6:before {
  content: '\F165';
}

.sncf-icons-itinerary-line-tram-7:before {
  content: '\F166';
}

.sncf-icons-itinerary-line-tram-8:before {
  content: '\F167';
}

.sncf-icons-itinerary-line-tram-9:before {
  content: '\F168';
}

.sncf-icons-itinerary-metro:before {
  content: '\F169';
}

.sncf-icons-itinerary-pedestrian:before {
  content: '\F16A';
}

.sncf-icons-itinerary-rer:before {
  content: '\F16B';
}

.sncf-icons-itinerary-train-station:before {
  content: '\F16C';
}

.sncf-icons-itinerary-train:before {
  content: '\F16D';
}

.sncf-icons-itinerary-tram:before {
  content: '\F16E';
}

.sncf-icons-itinerary-tramway:before {
  content: '\F16F';
}

.sncf-icons-label:before {
  content: '\F170';
}

.sncf-icons-large-building:before {
  content: '\F171';
}

.sncf-icons-large-clock:before {
  content: '\F172';
}

.sncf-icons-large-conversation:before {
  content: '\F173';
}

.sncf-icons-large-creditcard:before {
  content: '\F174';
}

.sncf-icons-large-france:before {
  content: '\F175';
}

.sncf-icons-large-group:before {
  content: '\F176';
}

.sncf-icons-large-headphones:before {
  content: '\F177';
}

.sncf-icons-large-information:before {
  content: '\F178';
}

.sncf-icons-large-itinerary-bike:before {
  content: '\F179';
}

.sncf-icons-large-itinerary-bus-2:before {
  content: '\F17A';
}

.sncf-icons-large-itinerary-bus:before {
  content: '\F17B';
}

.sncf-icons-large-itinerary-car:before {
  content: '\F17C';
}

.sncf-icons-large-itinerary-metro:before {
  content: '\F17D';
}

.sncf-icons-large-itinerary-pedestrian:before {
  content: '\F17E';
}

.sncf-icons-large-itinerary-rer:before {
  content: '\F17F';
}

.sncf-icons-large-itinerary-train-station:before {
  content: '\F180';
}

.sncf-icons-large-itinerary-train:before {
  content: '\F181';
}

.sncf-icons-large-itinerary-tram:before {
  content: '\F182';
}

.sncf-icons-large-itinerary-tramway:before {
  content: '\F183';
}

.sncf-icons-large-lightbulb:before {
  content: '\F184';
}

.sncf-icons-large-luggage:before {
  content: '\F185';
}

.sncf-icons-large-mail:before {
  content: '\F186';
}

.sncf-icons-large-phone:before {
  content: '\F187';
}

.sncf-icons-large-search-recent:before {
  content: '\F188';
}

.sncf-icons-large-seat:before {
  content: '\F189';
}

.sncf-icons-large-skateboard:before {
  content: '\F18A';
}

.sncf-icons-large-smartphone:before {
  content: '\F18B';
}

.sncf-icons-large-sncf:before {
  content: '\F18C';
}

.sncf-icons-large-stroller:before {
  content: '\F18D';
}

.sncf-icons-large-suitcase:before {
  content: '\F18E';
}

.sncf-icons-large-swipecard:before {
  content: '\F18F';
}

.sncf-icons-large-ticket:before {
  content: '\F190';
}

.sncf-icons-large-truck:before {
  content: '\F191';
}

.sncf-icons-large-warning:before {
  content: '\F192';
}

.sncf-icons-list:before {
  content: '\F193';
}

.sncf-icons-localisation-pin:before {
  content: '\F194';
}

.sncf-icons-lounge-car:before {
  content: '\F195';
}

.sncf-icons-menu-account:before {
  content: '\F196';
}

.sncf-icons-menu-burger:before {
  content: '\F197';
}

.sncf-icons-menu-searchbar:before {
  content: '\F198';
}

.sncf-icons-message:before {
  content: '\F199';
}

.sncf-icons-mosaic:before {
  content: '\F19A';
}

.sncf-icons-nursery-service:before {
  content: '\F19B';
}

.sncf-icons-options:before {
  content: '\F19C';
}

.sncf-icons-pause:before {
  content: '\F19D';
}

.sncf-icons-pencil:before {
  content: '\F19E';
}

.sncf-icons-play-area:before {
  content: '\F19F';
}

.sncf-icons-play:before {
  content: '\F1A0';
}

.sncf-icons-position:before {
  content: '\F1A1';
}

.sncf-icons-print:before {
  content: '\F1A2';
}

.sncf-icons-professionnal-area:before {
  content: '\F1A3';
}

.sncf-icons-quiet-area:before {
  content: '\F1A4';
}

.sncf-icons-refresh:before {
  content: '\F1A5';
}

.sncf-icons-rent-bike:before {
  content: '\F1A6';
}

.sncf-icons-sami-confused:before {
  content: '\F1A7';
}

.sncf-icons-sami-grin:before {
  content: '\F1A8';
}

.sncf-icons-sami-sad:before {
  content: '\F1A9';
}

.sncf-icons-sami-smile:before {
  content: '\F1AA';
}

.sncf-icons-search-recent:before {
  content: '\F1AB';
}

.sncf-icons-search:before {
  content: '\F1AC';
}

.sncf-icons-share:before {
  content: '\F1AD';
}

.sncf-icons-slider-off:before {
  content: '\F1AE';
}

.sncf-icons-slider-on:before {
  content: '\F1AF';
}

.sncf-icons-sort:before {
  content: '\F1B0';
}

.sncf-icons-support:before {
  content: '\F1B1';
}

.sncf-icons-tag:before {
  content: '\F1B2';
}

.sncf-icons-tgv-family:before {
  content: '\F1B3';
}

.sncf-icons-toolbar-bold:before {
  content: '\F1B4';
}

.sncf-icons-toolbar-center:before {
  content: '\F1B5';
}

.sncf-icons-toolbar-italic:before {
  content: '\F1B6';
}

.sncf-icons-toolbar-left:before {
  content: '\F1B7';
}

.sncf-icons-toolbar-list:before {
  content: '\F1B8';
}

.sncf-icons-toolbar-underline:before {
  content: '\F1B9';
}

.sncf-icons-twitter:before {
  content: '\F1BA';
}

.sncf-icons-volume-down:before {
  content: '\F1BB';
}

.sncf-icons-volume-up:before {
  content: '\F1BC';
}

.sncf-icons-warning:before {
  content: '\F1BD';
}

.sncf-icons-wifi-area:before {
  content: '\F1BE';
}

.sncf-icons-young-service:before {
  content: '\F1BF';
}
